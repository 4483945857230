
<template>
  <div style="width:100%">
    <Modal title="编辑战队名次" v-model="modal_edit_data" @on-ok="onUpdateResult" >
        <Form :label-width="100" style="margin-top:1px;" @submit.native.prevent>
            <FormItem label="ID:" v-if="edited_data.id">
                <Input v-model="edited_data.id"  disabled></Input>
            </FormItem>
            <FormItem label="队伍:">
                <TeamFinder :game_id="game_id" v-model="edited_data.team_id"></TeamFinder>
            </FormItem>
            <FormItem label="奖金:">
                <Input v-model="edited_data.price"></Input>
            </FormItem>
            <FormItem label="名次:">
                <Input v-model="edited_data.team_ranking"></Input>
            </FormItem>
            <FormItem label="删除:">
              <Select v-model="edited_data.deleted" >
                  <Option :value="1" key="1">是</Option>
                  <Option :value="0" key="0">否</Option>
              </Select>
            </FormItem>
        </Form>
    </Modal>

    <Card :bordered="false">
        <Row type='flex' align="middle">
            <Col span='4' >
                <Avatar shape="square" icon="ios-person" size="large" :src="tournament.logo" />
            </Col>
            <Col span='16' class_name="tournament_descs" push="1" align="left">
                <Row><span>{{tournament.name_zh || tournament.abbr_zh}}</span></Row>
                <Row><span>状态: {{
                    tournament.status_id===1?"未开始":(
                    tournament.status_id===2?"进行中":(
                    tournament.status_id===3?"已结束":'未知'
                    )
                  )}}</span></Row>
                <Row>时间: {{tournament_start_date}} - {{tournament_end_date}}</Row>
            </Col>
            <Col span="4" style="text-align: right">
                <Button type="success"  @click="add">新增</Button>
            </Col>
        </Row>
    </Card>
    <fitTable
      :columns="match_columns"
      :data="data"
      :header_height="250"
    ></fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
      ></Page>
  </div>

</template>

<script>

import { TournamentDetail, getResults, updateResult
 } from '@/api/games/tournament';
import moment from 'moment';
import TeamFinder from '../team/finder.vue';
import fitTable from '../../basic/table.vue';
// const delButton = (vm, h, params) => {
//   return h(
//     'Poptip',
//     {
//       props: {
//         confirm: true,
//         title: '您确定要删除这条数据吗?',
//         transfer: true
//       },
//       on: {
//         'on-ok': () => {
//             var data = {
//                 game_id: vm.game_id,
//                 tournament_id: vm.tournament_id,
//                 row_id: params.row.id,
//             }
//             DelBoardTeam(data).then(response => {
//               if (response.data.code == 0) {
//                 // vm..splice(params.index, 1);
//                 vm.getStates( vm.board_stage_id, vm.board_group_id );
//               } else {
//                 this.$Message.error(response.data.msg);
//               }
//             })
//         }
//       }
//     },
//     [
//       h(
//         'Button',
//         {
//           style: {
//             // margin: "0 5px"
//           },
//           props: {
//             type: 'warning',
//             size: "small"
//           }
//         },
//         '删除'
//       )
//     ]
//   )
// }

const editButton = (vm, h, params) => {
  return h(
    'Button',
    {
      props: {
        type: 'success',
        size: 'small'
      },
      on: {
        click: () => {
          // 展示模态框
          vm.edited_data = {
            id: params.row.id,
            price: params.row.price,
            team_ranking: params.row.team_ranking,
            team_id: params.row.team.id,
            deleted: params.row.deleted,
          }
          vm.modal_edit_data = true;
        }
      }
    },
    '编辑'
  )
}


export default {
  data () {
    let self = (window.x = this)
    return {
        game_id: 0,
        tournament_id: 0,
        loading: true,
        tournament: {
          logo: '',
        },
        data: [],
        match_columns: [
          {
            title: 'id',
            key: 'id',
          },
          {
              title: '战队',
              key: 'team',
              render: function (h, params) {
                  let currentRow = params.row;
                  return h(
                    'div', [
                      h('span', {
                        attrs: {
                        },
                      }, currentRow.team.name || ''),
                    ]
                  )
              }
          },
          {
            title: '奖金',
            key: 'price',
          },
          {
            title: '名次',
            key: 'team_ranking',
          },
          // {
          //   title: '奖金',
          //   key: 'price',
          // },
          {
              title: '操作',
              key: 'deleted',
              align: 'center',
              render (h, params) {
                  return [
                      editButton( self, h, params ),
                  ]
              }
          },
        ],
        stage_id: 0,
        date: '',
        total_page: 0,
        page_size: 0,
        current_page: 1,
        stage_groups: {},
        modal_edit_data: false,
        edited_data: {
          id: 0,
          price: '',
          team_ranking: '',
          team_id: 0,
          deleted: 0,
        },
        // end
    }
  },
  components: {
    TeamFinder,
    fitTable
  },
  methods: {
    // startPos
    getDataDetail(){
        let self = this;
        var params = {
            game_id: self.game_id,
            tournament_id: self.tournament_id,
        }
        TournamentDetail(params).then(response => {
            if (response.data.code == 0) {
                self.tournament = response.data.data;
            }else {
                self.$Message.error(response.data.msg);
            }
        })
    },
    // 获取联赛下的比赛列表
    getData(){
        let self = this;
        var params = {
            game_id: self.game_id,
            tournament_id: self.tournament_id,
            page: self.current_page,
        }
        getResults(params).then(response => {
            if (response.data.code === 0) {
                self.data = response.data.data.list;
                self.total_page = response.data.data.total_page;
                self.page_size = response.data.data.page_size;
                self.current_page = response.data.data.current_page;
                self.loading = false;
                // console.log(self.matches);
            }else {
                self.$Message.error(response.data.msg);
            }
        })
    },
    // end
    onPageChange(page){
      let self = this;
      self.current_page = page;
      self.getData();
    },
    // end
    onUpdateResult(){
        let self = this;
        var params = {
            id: self.edited_data.id,
            game_id: self.game_id,
            tournament_id: self.tournament_id,
            team_ranking: self.edited_data.team_ranking,
            team_id: self.edited_data.team_id,
            price: self.edited_data.price,
            deleted: self.edited_data.deleted,
        }
      updateResult(params).then(response => {
          if (response.data.code === 0) {
            self.$Message.success('更新成功');
            // console.log(self.matches);
            self.getData();
          }else {
            self.$Message.error(response.data.msg);
          }
      })
    },
    // end
    add () {
        this.edited_data = {
          id: 0,
          price: '',
          team_ranking: '',
          team_id: 0,
          deleted: 0,
        }
        this.modal_edit_data = true;
    },
    // end 
  },
  mounted () {
    let self = this;
    this.game_id = this.$route.query.game_id;
    this.tournament_id = this.$route.query.tournament_id;
    self.getDataDetail();
    self.getData();
  },
  watch: {
    //end
  },
  computed: {
    tournament_start_date: function(){
        let self = this;
        if (self.tournament){
          return moment.unix(self.tournament.start_date).format('YYYY-MM-DD')
        }
    },
    tournament_end_date: function(){
        let self = this;
        if (self.tournament){
          return moment.unix(self.tournament.end_date).format('YYYY-MM-DD')
        }
    },
  }
}
</script>
