<template>
  <div>
    <Input type="text" v-model="team_id" number placeholder="队伍id"></Input>
    <p>{{info}}</p>
    <row>
      <Input type="text" v-model="team_name" number placeholder="队伍名称"></Input>
      <Button type="primary" icon="ios-search" @click="searchTeam">搜索战队</Button>
      <row>
        <RadioGroup v-model="team_id" vertical>
          <template v-for="item in teamList">
            <Radio :label="item.id">
              <span>{{item.name}}</span>
            </Radio>
          </template>
        </RadioGroup>
      </row>
    </row>
  </div>
</template>
<script>
// import TournamentSelector from "../components/tournament-selector.vue";
import { TeamInfo, TeamSearch } from '@/api/games/team';

export default {
  name: 'team-finder',
  props: ['value', 'game_id'],
  components: {
    // TournamentSelector
  },
  data () {
    return {
      team_id: this.value,
      team_name: null,
      teamList: [],
      info: ''
    }
  },
  watch: {
    team_id (val) {
      this.getTeamInfo()
      this.$emit('input', val)
    },
    game_id (val) {
      this.$emit('on_game_id_update', val)
    },
    value (val) {
      this.team_id = val
    }
  },
  mounted () {
    this.team_id = this.value
    this.getTeamInfo()
  },
  methods: {
    getTeamInfo () {
      let self = this
      if (self.team_id) {
        TeamInfo({ id: self.team_id, game_id: self.game_id })
          .then(response => {
            if (response.data.info) {
              self.info = response.data.info.name
              self.teamList = []
            } else {
              self.info = ''
            }
          })
      } else {
        self.info = ''
      }
    },
    searchTeam () {
      let self = this
      if (self.game_id || self.team_name) {
        TeamSearch({
          game_id: self.game_id,
          name: self.team_name
        })
          .then(response => {
            // console.log(response)
            if (response.data.code == 0) {
              self.teamList = response.data.list
            }
          })
      }
    }
  }
}
</script>
